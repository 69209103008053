import React from "react"
import TrueLove from "../images/undraw_true_love_cy8x.svg"
import NewsWebsite from "../images/newsWebsite.svg"
import WeatherApp from "../images/weatherApp.svg"
import NewsApp from "../images/newsApp.svg"
import ShelterAllocation from "../images/shelterAllocation.svg"
import ProjectCardNew from "./ProjectCardNew"
import {CardGroup} from "react-bootstrap"
function Projects(){
    return (
        <div style={{marginLeft:"8%", marginRight:"8%", marginBottom:"32px"}}>
            <CardGroup>
                <ProjectCardNew
                    projectTitle = "Be SexEd!"
                    projectDescription = "Gamification of sex education to make it more accessible and interesting. Won Athena Hacks favourite award at USC’s hackathon"
                    projectDetailLink = "https://devpost.com/software/be-sexed-kgq5b2"
                    projectTags = {["HTML", "CSS", "Javascript", "PHP"]}
                    projectImage = {TrueLove}
                />
                <ProjectCardNew
                    projectTitle = "Get the latest!"
                    projectDescription = "A responsive news website built using NYTimes and Guardian APIs, with features like search, autosuggestion and bookmarking"
                    projectDetailLink = "https://github.com/anerishah97/NewsWebsite"
                    projectTags = {["React", "Express", "Bootstrap"]}
                    projectImage = {NewsWebsite}
                />

                <ProjectCardNew
                    projectTitle = "Daily news App"
                    projectDescription = "Native Android news app with category-wise news, article search with keywords, autosuggestions and a bookmarking functionality"
                    projectDetailLink = "https://github.com/anerishah97/NewsAppAndroid"
                    projectTags = {["Java", "XML", "ExpressJS"]}
                    projectImage = {NewsApp}
                />

                <ProjectCardNew
                    projectTitle = "Weather App"
                    projectDescription = "A native Android weather app with awesome UI, developed using Kotlin with daily and weekly weather, graphs and a photo gallery. "
                    projectDetailLink = ""
                    projectTags = {["Kotlin", "XML", "NodeJS"]}
                    projectImage = {WeatherApp}
                />

                <ProjectCardNew
                    projectTitle = "Shelter Allocation App"
                    projectDescription = "A disaster management native Android application developed during a hackathon, designed to allocate resources and shelters"
                    projectDetailLink = "https://github.com/anerishah97/sih2018"
                    projectTags = {["Java", "XML"]}
                    projectImage = {ShelterAllocation}
                />

                {/* <ProjectCardNew
                    projectTitle = "Daily news App"
                    projectDescription = "Native Android news app with category-wise news, article search with keywords, autosuggestions and a bookmarking functionality"
                    projectDetailLink = "https://github.com/anerishah97/NewsApp"
                    projectTags = {["Java", "XML", "ExpressJS"]}
                    projectImage = {NewsApp}
                /> */}
    
        </CardGroup>
        </div>
    )
}

export default Projects