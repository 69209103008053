import React from "react"
import Footer from "../components/Footer"
import Navbar from "../components/Navbar"
import Projects from "../components/Projects";

require('typeface-poppins')
function Home() {
  return (
    <div>
      <Navbar/>
      <Projects/>
      <Footer/>
    </div>
  );
}

export default Home
