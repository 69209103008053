import React from "react"
import {Card, Button} from "react-bootstrap"

function ProjectCardNew(props){
    var projectTags = props.projectTags;
    var tags = [];
    for(var i = 0; i<projectTags.length; i++){
        tags.push(<Card.Text className="p-1">
                    <span className ="badge text-uppercase"
                            style={{backgroundColor:"#18132f", 
                                    color:"#fff", 
                                    fontWeight:"normal",
                                    marginBottom:"0"  }}>
                            {projectTags[i]}            
                    </span>
                </Card.Text>
        )
    }
    return(
        <div className="col-lg-4 col-md-6 col-sm-12 mb-3 mt-4" style={{gridGap:"100px"}}>
        <Card className = "shadow">
            <div>
            
            <Card.Body>
            <Card.Title className="font-bold" style={{textAlign:"center"}}>{props.projectTitle}</Card.Title>  
            <div className="d-flex ml-1" style={{flexWrap:"wrap"}}>{tags}</div>
            <Card.Img className="img-thumbnail p-1 mt-1" variant="top" src={props.projectImage} style={{height:"200px"}}></Card.Img>           
            <Card.Text className="font-bold mr-auto p-2">{props.projectDescription}</Card.Text> 
                <div style={{display:"flex", justifyContent:"end"}}>
                <Button className="prj-details-button" id="" style={{backgroundColor:"#18132F"}} target="_blank"  href={props.projectDetailLink}>Details</Button>
                </div>  
            </Card.Body>
            </div>
        </Card>
        </div>
    )
}

export default ProjectCardNew